<template>
  <div class="content-wrap user-content-wrap" v-loading="loading">
    <bread-menu title="个人中心">
      <div class="nav-title">
        <router-link to="" tag="span" class="active">个人信息</router-link>
      </div>
    </bread-menu>
    <!-- 个人信息 -->
    <div class="person-info">
      <h5 class="common-title">个人信息</h5>
      <div class="person-box">
        <div class="box-wrap clearfix">
          <div class="profile">
            <div class="img">
              <img :src="user.headUrl" alt="" />
            </div>
            <p class="name">{{ user.name }}</p>
          </div>
          <div class="info">
            <div class="info-top clearfix">
              <div class="list">
                <div class="item item1">
                  <span>书丁号：</span>{{ user.bookReadId }}
                </div>
                <div class="item item2">
                  <span>年级：</span>{{ user.gradeName }}
                </div>
                <div class="item item3"><span>性别：</span>{{user.sex==1?"男":"女"}}</div>
              </div>
              <div class="list">
                <div class="item item1">
                  <span>手机号：</span>{{ user.mobile }}
                </div>
                <div class="item item2">
                  <span>科目：</span>{{ user.subject }}
                </div>
                <div class="item item3">
                  <span>生日：</span>{{ user.birthday }}
                </div>
              </div>
              <div class="list last">
                <div class="item item1">
                  <span>地区：</span>{{ user.provinceName }}
                </div>
                <div class="item item2">
                  <span>学校：</span>{{ user.school }}
                </div>
                <div class="item item3">
                  <span>累计上课时间：</span>{{ user.schoolTime }}
                </div>
              </div>
            </div>
            <div class="Introduction">
              <span>简介：</span>
              <p>
                {{ user.introduce }}
              </p>
            </div>
          </div>
          <div class="person-right">
            <ul class="clearfix">
              <li class="follow">
                <span>{{ user.gzNum }}</span>
                <p>关注 |</p>
              </li>
              <li class="fans">
                <span>{{ user.fansNum }}</span>
                <p>粉丝 |</p>
              </li>
              <li class="count">
                <span>{{ user.likeNum }}</span>
                <p>点赞数量</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 教师信息 -->
    <div class="teacher-info">
      <h5 class="common-title">教师信息</h5>
      <div class="teacher-box">
        <ul class="item-wrap">
          <li>
            <div class="item item1">
              <span>用户名：</span>
              <p>{{ acci.name }}</p>
            </div>
            <div class="item">
              <span>教师资格证编号：</span>
              <p>{{ acci.idCard }}</p>
            </div>
          </li>
          <li>
            <div class="item item1">
              <span>认证学段：</span>
              <p>{{ acci.sectionName }}</p>
            </div>
            <div class="item">
              <span>认证科目：</span>
              <p>{{ acci.subjectName }}</p>
            </div>
          </li>
          <li class="card-item">
            <span>身份证照片：</span>
            <div class="imgbox clearfix">
              <div class="img-list">
                <div class="img">
                  <img :src="acci.contrary" alt="" />
                </div>
                <p>
                  <span>身份证正面</span>
                </p>
              </div>
              <div class="img-list">
                <div class="img">
                  <img :src="acci.frontUrl" alt="" />
                </div>
                <p>
                  <span>身份证反面</span>
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item">
              <span>教师资格证：</span>
              <p>
                教师资格证
                <a :href="acci.credentialUrl" class="btn" target="_blank"
                  >查看</a
                >
              </p>
            </div>
          </li>
          <li>
            <div class="item">
              <span>申请时间：</span>
              <p>{{ acci.applyTime*1000 | formatDate}}</p>
            </div>
          </li>
          <li>
            <div class="item">
              <span>审核结果：</span>
              <p>{{ verify() }}</p>
            </div>
          </li>
          <li>
            <div class="item">
              <span>审核时间：</span>
              <p>{{ acci.verifyTime*1000 | formatDate }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date.js";
import breadMenu from "@/components/breadMenu.vue";
export default {
  components: {
    breadMenu,
  },
  data() {
    return {
      loading: true,
      userInfo: {},
      user: {},
      acci: {},
      params: {
        token: "",
        userId: "",
      },
    };
  },
  created() {
    this.params.token = window.localStorage.getItem("token");
    this.params.userId = window.localStorage.getItem("userId");
    this.getUserInfo();
  },
   filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
  methods: {
    getUserInfo() {
      this.$axios({
        method: "post",
        params: this.params,
        url: "/teacher/user/userInfo",
      }).then((res) => {
        this.loading=false;
        if (res.data.code !== "0000") {
          return this.$message.error(res.data.message);
        }
        this.userInfo = res.data.data;
        this.user = this.userInfo.user;
        this.acci = this.userInfo.acci;
      });
    },
    verify(){
      if(this.user.verify==1){
        return '待审核'
      }else if(this.user.verify==2){
        return '审核通过'
      }else{
        return '审核未通过'
      }
    }
  },
};
</script>
<style>
</style>