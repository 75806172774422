<template>
    <div>
         <div class="bread-menu"><span class="bread-title">{{title}}</span><slot></slot></div>
    </div>
</template>
<script>
export default {
    name:'headMenu',
    props:['title'],
}
</script>
<style lang="scss" scoped>

</style>