import { render, staticRenderFns } from "./breadMenu.vue?vue&type=template&id=4eb91bd3&scoped=true&"
import script from "./breadMenu.vue?vue&type=script&lang=js&"
export * from "./breadMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb91bd3",
  null
  
)

export default component.exports